import { useEffect, useState, useRef } from "react";
import styles from "./CompanyHeaderPanel.module.css";

export const CompanyHeaderPanel = ({
  data,
  onDataChange,
  onClick,
  width = "100%",   
  height = "auto",  
  otherStyles = {},  
}) => {
  const [labelWidth, setLabelWidth] = useState(0)
  const labelRefs = useRef({})

  useEffect(() => {
    const maxLabelWidth = Object.keys(labelRefs.current).reduce((maxWidth, key) => {
      const ref = labelRefs.current[key]
      if (ref && ref.offsetWidth) {
        return Math.max(maxWidth, ref.offsetWidth)
      }
      return maxWidth
    }, 0)

    setLabelWidth(maxLabelWidth)
  }, [data])

  const panelStyles = {
    width: width,
    height: height,
    ...otherStyles, 
  }

  return (
    <div className={styles["panel"]} style={panelStyles}>
      <h4 className={styles["title"]}>Company Details</h4>
      {data && typeof data === "object" && Object.entries(data).length > 0 ? (
        Object.entries(data).map(([key, [label, value]]) => (
          <div key={key} className={styles["field"]}>
            <span 
              className={styles["label"]} 
              ref={el => labelRefs.current[key] = el} 
              style={{ minWidth: labelWidth }}>{label}:
            </span>
            <input
              className={styles["input"]}
              type="text"
              value={value}
              onChange={(e) => onDataChange(key, e)}
              style={{ width: `calc(100% - ${labelWidth + 30}px)`, marginLeft: '10px' }}
              readOnly
            />
            <button 
              className={styles["selectButton"]}
              onClick={() => onClick(value)} 
            ></button>
          </div>
        ))
      ) : (
        <div className={styles["field"]}>
          <span className={styles["label"]}>No company selected.</span>
        </div>
      )}
    </div>
  )
}

