import axios from "axios"
import { parseApiError } from '../utils/parsers/parseApiError';
import { getEndpoints, parseEndpoint } from '../utils/helpers/endpoints';
import { BASE_URL, NO_ENDPOINT_ERROR } from '../utils/constants';

export const refreshAccessToken = async () => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["users", "login"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    try {
        const response = await axios.post(
            `${BASE_URL}/${parsedEndpoint}/refresh_token`,
            {},
            { withCredentials: true }
        )
        return {
            success: true,
            minutesValid: response.data.minutes_valid,
            accessToken: response.data.access_token
        }
            
    } catch(error) {
        return parseApiError(error)
    }
}
