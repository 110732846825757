import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';
import { getEndpoints, parseEndpoint } from '../utils/helpers/endpoints';
import { BASE_URL, NO_ENDPOINT_ERROR } from '../utils/constants';

export const fetchAllBatchesInWork = async (authContext, ...config) => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["batches", "for_combo_box"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }
    
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = {
        ...config.headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.get(
            `${BASE_URL}/${parsedEndpoint}`,
            {
                params: config.params,
                timeout: config.timeout,
                headers: headers
            }
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}

export const createBatch = async (authContext, config) => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["batches"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = {
        ...config.headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    const { payload } = config

    try {
        const response = await axios.post(
            `${BASE_URL}/${parsedEndpoint}`,
            payload,
            {
                headers: headers
            }
        )

        const data = response.data
        
        return {    
            success: true,
            message: data.detail,
            batchID: data.result.id
        }
    } catch (error) {
        return parseApiError(error)
    }
}