export const defaultScreenCenter = [54.5260, 15.2551]
export const defaultZoom = 4
export const topHomePagePaddings = ["3%", "40%", "8%"]
export const topCompanyLocationsPagePaddings = ["3%", "50%", "8%"]
export const topDownHomePagePaddings = ["3.3%", "20%", "2.5%"]

export const additionalCompanyLocationsConfig = {
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    }
}

export const databaseSubmitLabel = "Submit To Database"
export const authLogoutLabel = "Logout"

export const companyLocationsFilteredFieldNames = [
    "Location Name", 
    "Full Address",
    "Country",
    // "Continent", 
    "Source 1 link"
]

export const NO_CSV_ERROR_MESSAGE = ""

export const logoutButtonOptions = {
    fontSize: "1.2rem",
    color: "#fff",
    bgColor: "#ff4c4c",
    borderColor: "transparent",
    borderRadius: "50px",
    boxShadow: "0 4px 12px rgba(255, 0, 0, 0.2)",
    hoverBgColor: "#e43e3e",
    hoverBorderColor: "transparent",
    hoverBoxShadow: "0 6px 16px rgba(255, 0, 0, 0.3)",
    focusBorderColor: "#ff0000",
    focusBoxShadow: "0 0 0 4px rgba(255, 0, 0, 0.3)",
    activeBgColor: "#cc0000",
    activeBoxShadow: "0 2px 6px rgba(255, 0, 0, 0.4)",
    width: "180px",
    height: "50px"
}

const API_VERSION = `api/${process.env.REACT_APP_VERSION}`
const DOMAIN = process.env.REACT_APP_FRONTEND_IP
const PROTOCOL = process.env.REACT_APP_PROTOCOL
const BACKEND_PORT = process.env.REACT_APP_BACKEND_PORT

export const BASE_URL = `${PROTOCOL}://${DOMAIN}` +
    (DOMAIN === "localhost" 
        ? `:${BACKEND_PORT}` 
        : '') +
    `/${API_VERSION}`;

export const NO_ENDPOINT_ERROR = "Endpoint does not exist in endpoints.json."

export const ALL_BATCHES_PLACEHOLDER = "All Batches: Choose a batch from all the batches available."
export const PERSONAL_BATCHES_PLACEHOLDER = "Personal Batches: Choose a batch from your personal batches."

export const BATCHES_WIDTH = "600px"
export const BATCHES_HEIGHT = "50px"
