import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HomePage } from './pages/HomePage/HomePage'
import { LoadDataPage } from './pages/LoadDataPage/LoadDataPage'
import { PopupPage } from './pages/PopupPage/PopupPage'
import { ToastContainer } from 'react-toastify'
import { BatchAccessPage } from './pages/BatchAccessPage/BatchAccessPage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'

export const App = () => {
  return (
    <Router>
      <ToastContainer/>
      <Routes>
        <Route path="/" element={<PopupPage />}></Route>
        <Route path="/data_selection" element={<LoadDataPage />} />
        <Route path="/batch_access" element={<BatchAccessPage />}></Route>
        <Route path="/home_page" element={<HomePage />}></Route>
        <Route path="*" element={ <NotFoundPage />}> </Route>
      </Routes>
    </Router>
  )
}


