export class Token {
    static #getCurrentTime() {
        return Math.floor(Date.now() / 1000)
    }

    static calculateExpirationTime(minutesValid) {
        const currentTimeInSeconds = Token.#getCurrentTime()
        const expirationTimeInSeconds = currentTimeInSeconds + (minutesValid * 60)
        return expirationTimeInSeconds
    }

    static #isTokenExpired(expirationTime) {
        const currentTimeInSeconds = Token.#getCurrentTime()
        return currentTimeInSeconds >= expirationTime
    }

    static #updateAuthContext(authContext, accessToken, minutesValid) {
        authContext.dispatch(authContext.setAccessToken(accessToken))
    
        const updatedExpirationTime = Token.calculateExpirationTime(minutesValid)
        authContext.dispatch(authContext.setExpirationTime(updatedExpirationTime))
    }

    static #handleRefreshFailure(authContext) {
        console.error("Failed to refresh access token, redirecting to login...");
        authContext.toLoadingPage()
    }

    static #getFreshAccessToken = async (authContext) => {
        const tokenExpirationTime = authContext.tokenExpiration

        if (Token.#isTokenExpired(tokenExpirationTime)) {
            const response = await authContext.refreshAccessTokenFn()
            
            if (response.success) {
                const { accessToken, minutesValid } = response

                Token.#updateAuthContext(authContext, accessToken, minutesValid)

                return accessToken
            } else {
                Token.#handleRefreshFailure(authContext)
            }
        }    
    }

    static refreshAccessTokenIfAuthenticated = async (authContext) => {
        const { isAuthenticated, ...restAuthContext } = authContext
        if(isAuthenticated) {
            const jwt = await Token.#getFreshAccessToken(restAuthContext)
            return jwt
        }

        return null
    }
}


