// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FancyPopup_fancy-toast__JagMF {
    width: 650px;  
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    padding: 20px;  
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
    position: fixed; 
    top: 50%;  
    left: 50%;  
    transform: translate(-50%, 15%);  
    z-index: 9999;  
}

.FancyPopup_fancy-toast-body__Lu0YJ {
    font-size: 22px;  
    padding: 10px;
    line-height: 1.6;  
}
  `, "",{"version":3,"sources":["webpack://./src/components/FancyPopup/FancyPopup.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,yCAAyC;IACzC,eAAe;IACf,QAAQ;IACR,SAAS;IACT,+BAA+B;IAC/B,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".fancy-toast {\n    width: 650px;  \n    margin: 0 auto;\n    text-align: center;\n    border-radius: 10px;\n    padding: 20px;  \n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); \n    position: fixed; \n    top: 50%;  \n    left: 50%;  \n    transform: translate(-50%, 15%);  \n    z-index: 9999;  \n}\n\n.fancy-toast-body {\n    font-size: 22px;  \n    padding: 10px;\n    line-height: 1.6;  \n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fancy-toast": `FancyPopup_fancy-toast__JagMF`,
	"fancy-toast-body": `FancyPopup_fancy-toast-body__Lu0YJ`
};
export default ___CSS_LOADER_EXPORT___;
