// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GridListBoxCount_item-count-panel__M1Frt {
    border: 1px solid #ccc;
    border-radius: 12px; 
    background-color: #f9f9f9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column; 
    align-items: stretch; 
    width: 100%; 
    max-width: 1250px; 
    margin-left: auto; 
    margin-right: auto; 
}

.GridListBoxCount_item-count__zTJE- {
    font-size: 1.2rem; 
    color: black; 
    font-weight: 600;
    margin-bottom: 16px; 
    text-align: right; 
}

.GridListBoxCount_item-count__zTJE- strong {
    font-size: 1.6rem; 
}

.GridListBoxCount_grid-list-box__gt5rf {
    width: 100%; 
    box-sizing: border-box; 
}

.GridListBoxCount_grid-listbox-container-wrapper__rnJZM {
    margin: 0 auto; 
    width: 100%; 
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.GridListBoxCount_scroll-pane__GMwdI {
    overflow-y: auto;
    max-height: 80vh;
    background-color: #f8f9fa;
    border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/GridListBoxCount/GridListBoxCount.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;IACzB,wCAAwC;IACxC,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":[".item-count-panel {\n    border: 1px solid #ccc;\n    border-radius: 12px; \n    background-color: #f9f9f9;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    padding: 20px;\n    margin-bottom: 20px;\n    display: flex;\n    flex-direction: column; \n    align-items: stretch; \n    width: 100%; \n    max-width: 1250px; \n    margin-left: auto; \n    margin-right: auto; \n}\n\n.item-count {\n    font-size: 1.2rem; \n    color: black; \n    font-weight: 600;\n    margin-bottom: 16px; \n    text-align: right; \n}\n\n.item-count strong {\n    font-size: 1.6rem; \n}\n\n.grid-list-box {\n    width: 100%; \n    box-sizing: border-box; \n}\n\n.grid-listbox-container-wrapper {\n    margin: 0 auto; \n    width: 100%; \n    box-sizing: border-box;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.scroll-pane {\n    overflow-y: auto;\n    max-height: 80vh;\n    background-color: #f8f9fa;\n    border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item-count-panel": `GridListBoxCount_item-count-panel__M1Frt`,
	"item-count": `GridListBoxCount_item-count__zTJE-`,
	"grid-list-box": `GridListBoxCount_grid-list-box__gt5rf`,
	"grid-listbox-container-wrapper": `GridListBoxCount_grid-listbox-container-wrapper__rnJZM`,
	"scroll-pane": `GridListBoxCount_scroll-pane__GMwdI`
};
export default ___CSS_LOADER_EXPORT___;
