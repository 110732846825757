import axios from 'axios';
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';
import { getEndpoints, parseEndpoint } from '../utils/helpers/endpoints';
import { BASE_URL, NO_ENDPOINT_ERROR } from '../utils/constants';

export const insertFeedback = async (authContext, requestData) => {
    let { payload, headers } = requestData

    const parsedEndpoint = parseEndpoint(getEndpoints(), ["feedbacks"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    headers = {
        ...headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.post(
            `${BASE_URL}/${parsedEndpoint}`,
            payload,
            {
                headers: headers
            }
        )

        if (response.status === 201) {
            return {
                success: true,
                data: response.data
            }
        } else {
            return {
                success: false,
                error: `Unexpected response status: ${response.status}. Expected 201 Created.`
            }
        }
    } catch (error) {
        return parseApiError(error)
    }
}

export const fetchAnalyticsCSV = async (authContext, requestData, queryParams) => {
    let { headers } = requestData

    const parsedEndpoint = parseEndpoint(getEndpoints(), ["feedbacks", "csv"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    headers = {
        ...headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.get(
            `${BASE_URL}/${parsedEndpoint}`,
            {
                headers: headers,
                params: queryParams
            }
        )
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return parseApiError(error)
    }
}