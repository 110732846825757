// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_container__XkGLH {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
    text-align: center;
    padding: 20px;
  }
  
  .NotFoundPage_content__lMWm- {
    max-width: 600px;
    margin: auto;
  }
  
  .NotFoundPage_title__uDivS {
    font-size: 72px;
    font-weight: bold;
    color: #dc3545;
  }
  
  .NotFoundPage_subtitle__vw\\+kR {
    font-size: 36px;
    color: #343a40;
    margin: 10px 0;
  }
  
  .NotFoundPage_description__ZSdN4 {
    font-size: 18px;
    color: #6c757d;
    margin-bottom: 20px;
  }
  
  .NotFoundPage_backLink__li1E7 {
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .NotFoundPage_backLink__li1E7:hover {
    background-color: #0056b3;
  }
  
  .NotFoundPage_illustration__Te0bV {
    margin-top: 20px;
    width: 100%;
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,cAAc;EAChB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;IACrB,kBAAkB;IAClB,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;IAChB,WAAW;IACX,YAAY;EACd","sourcesContent":[".container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f8f9fa;\n    text-align: center;\n    padding: 20px;\n  }\n  \n  .content {\n    max-width: 600px;\n    margin: auto;\n  }\n  \n  .title {\n    font-size: 72px;\n    font-weight: bold;\n    color: #dc3545;\n  }\n  \n  .subtitle {\n    font-size: 36px;\n    color: #343a40;\n    margin: 10px 0;\n  }\n  \n  .description {\n    font-size: 18px;\n    color: #6c757d;\n    margin-bottom: 20px;\n  }\n  \n  .backLink {\n    padding: 10px 20px;\n    background-color: #007bff;\n    color: #ffffff;\n    text-decoration: none;\n    border-radius: 5px;\n    transition: background-color 0.3s;\n  }\n  \n  .backLink:hover {\n    background-color: #0056b3;\n  }\n  \n  .illustration {\n    margin-top: 20px;\n    width: 100%;\n    height: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `NotFoundPage_container__XkGLH`,
	"content": `NotFoundPage_content__lMWm-`,
	"title": `NotFoundPage_title__uDivS`,
	"subtitle": `NotFoundPage_subtitle__vw+kR`,
	"description": `NotFoundPage_description__ZSdN4`,
	"backLink": `NotFoundPage_backLink__li1E7`,
	"illustration": `NotFoundPage_illustration__Te0bV`
};
export default ___CSS_LOADER_EXPORT___;
