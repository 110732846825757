import { fromLabelGetValue } from "../utils/comboBoxOptions/correctOptions"

export class Feedback {
    
    static existsFeedbacks(feedbacks) {
        return feedbacks.length > 0
    } 

    static getLastElement(feedbacks) {
        return feedbacks[feedbacks.length - 1]
    }
    
    static fromJsonToObject(feedbackJson, options) {
        return {
            urlReviewCategory: fromLabelGetValue(options?.urlReviewCategories, feedbackJson.url_review_category),
            urlReviewComment: feedbackJson.url_review_comment,
            locationsReviewCategory: fromLabelGetValue(options?.locationsReviewCategories, feedbackJson.locations_review_category),
            locationsReviewComment: feedbackJson.locations_review_comment
        }
    }

    static setFeedbackData(setters, feedback) {
        Object.keys(feedback).forEach((feedbackField, index) => {
            const setter = setters[index]
            const value = feedback[feedbackField] === null ? "" : feedback[feedbackField]
            
            if (setter) {
                setter(value)
            }
        })
    }
}