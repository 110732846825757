import React, { useState, useRef, useEffect } from 'react'
import { ListBox } from 'primereact/listbox'
import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import styles from './CompanyListBox.module.css'

export const CompanyListBox = ({ companies, selectedCompany, onChange, isEnabled, companyIndex }) => {
    const [previousSelectedRow, setPreviousSelectedRow] = useState(selectedCompany)
    const listItemRefs = useRef({})

    const options = companies.map(([name, id, color], index) => ({
        index: index + 1,
        companyID: id,
        companyName: name,
        style: { backgroundColor: color.toLowerCase() },
        searchText: `${index + 1}.${name}`
    }))

    const handleChange = (event) => {
        const companyID = event.value

        if (previousSelectedRow && companyID === null) {
            onChange(previousSelectedRow)
            return
        }

        const selectedOption = options.find(option => option.companyID === companyID)
        setPreviousSelectedRow(selectedOption)
        onChange(selectedOption)
    }

    useEffect(() => {
        if (previousSelectedRow) {
            const selectedIndex = options.findIndex(option => option.companyID === previousSelectedRow.companyID)
            companyIndex.setSelectedIndex(selectedIndex)

            const itemRef = listItemRefs.current[previousSelectedRow.companyID]
            if (itemRef) {
                itemRef.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
            }
        }
    }, [previousSelectedRow, options, companyIndex])

    return (
        isEnabled ? (
            <div className={styles["company-listbox-container"]}>
                <div className={styles["company-listbox"]}>
                    <h1 className={styles["company-listbox-header"]}>Select a Company</h1>
                    <div className={styles["scroll-pane"]}>
                        <ListBox
                            value={selectedCompany}
                            options={options}
                            onChange={handleChange}
                            filter
                            optionLabel="searchText"
                            optionValue="companyID"
                            className={styles["p-listbox"]}
                            placeholder="Select a Company"
                            itemTemplate={(option) => (
                                <div
                                    className={styles["p-listbox-item"]}
                                    style={{
                                        ...option.style,
                                        color: option.style.backgroundColor === 'green'
                                            ? '#ffffff'
                                            : '#4a4a4a'
                                    }}
                                    ref={el => listItemRefs.current[option.companyID] = el}
                                >
                                    <span>{option.index}. </span>
                                    {option.companyName}
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        ) : <div className={styles["empty"]}></div>
    )
}

