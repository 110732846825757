import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';
import { getEndpoints, parseEndpoint } from '../utils/helpers/endpoints';
import { BASE_URL, NO_ENDPOINT_ERROR } from '../utils/constants';

export const fetchCompanyLocations = async (authContext, companyIDPathParam) => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["companies", "locations"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }
    
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = accessToken 
    ? { 'Authorization': `Bearer ${accessToken}` } 
    : {}

    try {
        const response = await axios.get(
            `${BASE_URL}/${parsedEndpoint}/${companyIDPathParam}`,
            {
                headers: headers
            }
            
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}