export const resetToDefault = (defaultValue, ...args) => {
    args.forEach(arg => {
        arg(defaultValue)
    })
}

export const setVisibilityToDefault = (args) => {
    args.forEach(arg => {
        arg(false)
    })
}