import { toast } from 'react-toastify'
import { Reader } from '../../utils/helpers/reader';
import { updateCompanyFeedbackStatus } from '../../utils/helpers/pages/homePageHelpers'

export const onButtonSubmitToDatabaseHelper = async ({
    companyID,
    urlReviewCategory,
    urlReviewComment,
    locationsReviewCategory,
    locationsReviewComment,
    insertFeedback,
    authContext,
    setCompanyLocationsInformation,
    companies,
    setCompanies,
    defaultCompanyLocationsInformation,
}) => {

    const requestData = {
        payload: {
            company_id: companyID,
            url_review_category: urlReviewCategory,
            ...(urlReviewComment && { url_review_comment: urlReviewComment }),
            locations_review_category: locationsReviewCategory,
            ...(locationsReviewComment && { locations_review_comment: locationsReviewComment }),
        },
        headers: {
            contentType: "application/json"
        }
    }

    try {
        const result = await insertFeedback(authContext, requestData)
        if (result.success) {
            toast.success("Data submitted successfully!")
            
            const companiesToUpdate = updateCompanyFeedbackStatus(companies, companyID)
            setCompanies(companiesToUpdate)
        } else {
            toast.error(`Failed to submit data: ${result.error}`)
        }
    } catch (error) {
        toast.error("An unexpected error occurred while submitting data.")
    } finally {
        setCompanyLocationsInformation(defaultCompanyLocationsInformation)
    }
}

export const onButtonRedirectHelper = async ({
    navigate,
    location,
}) => {
    navigate(location)
}

export const onFilePickerChangeHelper = async (files, { setFileName, setFileContent }) => {
    if (files.length === 0) {
        setFileContent(null)
        return { success: false, message: "No file selected." }
    }

    const file = files[0]
    const fileName = file.name
    
    if (!fileName.endsWith(".csv") || fileName.endsWith(".ms-excel")) {
        setFileContent(null)
        return { success: false, message: "Invalid file type. Please select a .csv file." }
    }

    try {
        const reader = new Reader(file)
        const content = await reader.read().then(() => reader.build())
        setFileContent(content)
        setFileName(fileName)
        return { success: true, message: "File content successfully loaded." }
    } catch (error) {
        setFileContent(null)
        console.error("File reading error:", error)
        return { success: false, message: `Error reading file: ${error.message}` }
    }
}

export const copyTextToClickboard = async (value, displaySuccessMessage = false) => {
    try {
        await navigator.clipboard.writeText(value)
   
        if(displaySuccessMessage) {
            toast.success("Successfully copied text")
        }
       
      } catch (error) {
        console.error(`Failed to copy text: ${error}`)
        toast.error("Failed to copy text")
      }
} 

export const onButtonLogoutImpl = async ({
    navigate,
    clearAccessToken,
    clearRefreshToken,
    rollback,
    errorPopup,
}) => {

    clearAccessToken()

    const clearedRefreshTokenResponse = await clearRefreshToken()

    if(!clearedRefreshTokenResponse.success) {
        rollback()
        errorPopup(clearedRefreshTokenResponse.error)
        return
    }

    navigate()
}

export const onButtonLogout = async (
    navigate, 
    dispatch, 
    setJWT, 
    logout, 
    jwt, 
    error
) => {
    await onButtonLogoutImpl({
        navigate: () => navigate("/"),
        clearAccessToken: () => dispatch(setJWT("")),
        clearRefreshToken: async () => logout(),
        rollback: () => dispatch(setJWT(jwt)),
        errorPopup: error,
    })
} 