import React from 'react';
import { IgrMultiColumnComboBox } from 'igniteui-react-grids';
import { VerticalPadding } from '../VerticalPadding/VerticalPadding';
import { Batches } from '../../models/batches';
import { 
    ALL_BATCHES_PLACEHOLDER, 
    PERSONAL_BATCHES_PLACEHOLDER, 
    BATCHES_WIDTH, 
    BATCHES_HEIGHT 
} from '../../utils/constants';

export const BatchSelectionComboBoxList = ({ batches, id, onSelectedValueChanged, isInteralUser = true }) => {
    const comboBoxConfig = {
        'allBatches': {
            width: BATCHES_WIDTH,
            height: BATCHES_HEIGHT,
            defaultColumnWidth: 150,
            placeholder: ALL_BATCHES_PLACEHOLDER,
            dataSource: Batches.filteredBatchColumnsForInternalUserBatches(batches),
        },
        'internalUserBatches': {
            width: BATCHES_WIDTH,
            height: BATCHES_HEIGHT,
            defaultColumnWidth: 200,
            placeholder: PERSONAL_BATCHES_PLACEHOLDER,
            dataSource: Batches.filteredBatchColumnsForExternalUserBatches(
                Batches.filterBatchesByUserId(batches, id)
            ),
        },
        'externalUserBatches': {
            width: BATCHES_WIDTH,
            height: BATCHES_HEIGHT,
            defaultColumnWidth: 200,
            placeholder: PERSONAL_BATCHES_PLACEHOLDER,
            dataSource: Batches.filteredBatchColumnsForExternalUserBatches(batches),
        }
    }

    if(isInteralUser) {
        return (
            <div>
                <div>
                    <IgrMultiColumnComboBox
                        width={comboBoxConfig.allBatches.width}
                        height={comboBoxConfig.allBatches.height}
                        defaultColumnWidth={comboBoxConfig.allBatches.defaultColumnWidth}
                        placeholder={comboBoxConfig.allBatches.placeholder}
                        dataSource={comboBoxConfig.allBatches.dataSource}
                        selectedValueChanged={onSelectedValueChanged}
                    />
                </div>
                <VerticalPadding paddingSizeType="small" />
                <div>
                    <IgrMultiColumnComboBox
                        width={comboBoxConfig.internalUserBatches.width}
                        height={comboBoxConfig.internalUserBatches.height}
                        defaultColumnWidth={comboBoxConfig.internalUserBatches.defaultColumnWidth}
                        placeholder={comboBoxConfig.internalUserBatches.placeholder}
                        dataSource={comboBoxConfig.internalUserBatches.dataSource}
                        selectedValueChanged={onSelectedValueChanged}
                    />
                </div>
            </div>
        )
    }
     
    return (
        <div>
            <IgrMultiColumnComboBox
                    width={comboBoxConfig.externalUserBatches.width}
                    height={comboBoxConfig.externalUserBatches.height}
                    defaultColumnWidth={comboBoxConfig.externalUserBatches.defaultColumnWidth}
                    placeholder={comboBoxConfig.externalUserBatches.placeholder}
                    dataSource={comboBoxConfig.externalUserBatches.dataSource}
                    selectedValueChanged={onSelectedValueChanged}
            />
        </div>
    )
}


