import 'react-data-grid/lib/styles.css';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';

const dataGridStyles = (theme) => ({
    boxShadow: 2,
    border: 2,
    borderRadius: '12px',
    '& .MuiDataGrid-cell:hover': {
        backgroundColor: 'rgba(52, 6, 6, 0.1)',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-virtualScroller': {
        backgroundColor: theme.palette.background.default,
    },
    '& .MuiDataGrid-row.selected': {
        backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell.selected': {
        backgroundColor: 'transparent',
    },
})

const getContainerStyles = () => {
    const height = window.innerHeight * 0.7;

    return {
        height: height < 400 ? '400px' : height, 
        width: '90%', 
        maxWidth: '1200px',
        overflow: 'hidden',
        margin: '0 auto', 
        border: '2px solid #000',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '10px', 
    }
}

const getColumns = (customColumns) => {
    return customColumns.map((col) => ({
        ...col,
        headerClassName: 'header-class', 
        cellClassName: 'cell-class', 
    }))
}

export const UserAccessGrid = ({ columns, rows, onSelectedRows, config = {} }) => {    
    
    const isLoading = config.isLoading || false

    if (isLoading) {
        return <LoadingSpinner />
    }    

    const containerStyles = getContainerStyles()

    return (
        <div style={containerStyles}>
            <DataGrid
                columns={getColumns(columns)} 
                rows={rows}
                getRowId={(row) => row.id}
                rowHeight={config.rowHeight || 60}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: config.pageSize || 10,
                        },
                    },
                }}
                onRowSelectionModelChange={onSelectedRows}
                pageSizeOptions={[config.pageSizeOptions || 8]}
                checkboxSelection
                disableRowSelectionOnClick
                sx={dataGridStyles({ palette: { background: { default: '#fff' } } })} 
            />
        </div>
    )
}
