export const handleSpecificInputChange = (id, value, ...callbacks) => {
    switch (id) {
        case "comment-url-combo-box":
            const isUrlInputVisible = value !== "option1"
            callbacks[0](isUrlInputVisible)
            break
        case "comment-locations-combo-box":
            const isLocationsIputVisible = value !== "option1"
            callbacks[1](isLocationsIputVisible)
            break
        default:
            break
    }
}