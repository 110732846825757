export class Users {
    static fromJsonToObject(jsonUsers) {
        return jsonUsers.map(jsonUser => _User.fromJson(jsonUser))
    }   

    static isUserListEmpty(users) {
        return users.length === 0
    }

    static excludeUserByID(users, id) {
        return users.filter(user => user.id !== id)
    }
}

class _User {
    static fromJson(json) {
        return {
            id: json.id,
            fullName: json.full_name,
            username: json.username,
            email: json.email === null ? "N/A" : json.email,
            internalUser: json.internal_user ? "Yes" : "No",
        }
    }
}


