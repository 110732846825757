import { toast } from 'react-toastify';
import styles from './FancyPopup.module.css';

export const triggerFancyPopup = (message) => {
  toast.error(message, {
    autoClose: false,
    closeOnClick: false,
    draggable: false,
    className: styles["fancy-toast"], 
    bodyClassName: styles["fancy-toast-body"], 
    position: "top-center"
  })
}


