import { FancyButton } from "../../components/FancyButton/FancyButton";
import { FancyComboBox } from "../../components/FancyComboBox/FancyComboBox";
import { FancyTextInput } from "../../components/FancyTextInput/FancyTextInput";
import { topDownHomePagePaddings } from "../constants";

export const topHomePageRows = [
    [
        {
            component: (value, onChange, isEnabled = true, onClick = null, options = {}) => (
                <FancyTextInput
                    placeholder={options.placeholder || "Company"}
                    value={value}
                    onChange={onChange}
                    width={options.width || "100%"}
                    height={options.height || "50px"}
                    shape={options.shape || "pill"}
                    onClick={onClick}
                    isEnabled={isEnabled}
                />
            ),
        },
        {
            component: (id, selected, value, onChange, options = {}) => (
                <FancyComboBox
                    id={id}
                    starterText={options.starterText || "Correct URL?"}
                    options={selected}
                    value={value}
                    onChange={onChange}
                />
            ),
        },
        {
            component: (value, onChange, isVisible, isEnabled = true, onClick = null, options = {}) => (
                <FancyTextInput
                    placeholder={options.placeholder || "Comment"}
                    value={value}
                    onChange={onChange}
                    width={options.width || "100%"}
                    height={options.height || "50px"}
                    shape={options.shape || "pill"}
                    onClick={onClick}
                    isEnabled={isEnabled}
                    isVisible={isVisible}
                />
            ),
            newLine: true,
        },
    ],
    [
        {
            component: (value, onChange, isEnabled = true, onClick = null, options = {}) => (
                <FancyTextInput
                    placeholder={options.placeholder || "User?"}
                    value={value}
                    onChange={onChange}
                    width={options.width || "100%"}
                    height={options.height || "50px"}
                    shape={options.shape || "pill"}
                    onClick={onClick}
                    isEnabled={isEnabled}
                />
            ),
        },
        {
            component: (id, selected, value, onChange, options = {}) => (
                <FancyComboBox
                    id={id}
                    starterText={options.starterText || "Correct Locations?"}
                    options={selected}
                    value={value}
                    onChange={onChange}
                />
            ),
        },
        {
            component: (value, onChange, isVisible, isEnabled = true, onClick = null, options = {}) => (
                <FancyTextInput
                    placeholder={options.placeholder || "Comment"}
                    value={value}
                    onChange={onChange}
                    width={options.width || "100%"}
                    height={options.heught || "50px"}
                    shape={options.shape || "pill"}
                    onClick={onClick}
                    isEnabled={isEnabled}
                    isVisible={isVisible}
                />
            ),
            newLine: true,
        },
    ],
];

export const topDownHomePageRows = [
    [
        {
            component: (label, onClick, options = {}) => (
                <FancyButton
                    onClick={onClick}
                    bgColor={options.bgColor || "#808080"}
                    hoverBgColor={options.hoverBgColor || "#000000"}
                    width={options.width || "100%"}
                    height={options.height || "50px"}
                    paddingLeft={options.paddingLeft || "0%"}
                    enabled={options.enabled ?? true}
                >
                    {label}
                </FancyButton>
            ),
            padding: topDownHomePagePaddings[0]
        },
        {
            component: (label, onClick, options = {}) => (
                <FancyButton
                    onClick={onClick}
                    {...options}
                >
                    {label}
                </FancyButton>
            ),
            padding: topDownHomePagePaddings[1]
        }
    ]
]
