export const fromValueGetLabel = (options, value) => {
    const option = options.find(option => option.value === value)
    return option?.label
}

export const fromLabelGetValue = (options, label) => {
    const option = options.find(option => option.label === label)
    return option?.value
}

export const hasLabelFromValue = (options, value) => {
    const option = options.find(option => option.value === value)
    return option?.label !== undefined
}