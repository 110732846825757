import { NoLengthMatchError } from '../../utils/errors'

export class DataConvertor {
    static stringToBase64(inputString) {
        const bytes = new TextEncoder().encode(inputString)
        const binaryString = Array.from(bytes).reduce((acc, byte) => acc + String.fromCharCode(byte), "")
        return btoa(binaryString)
    }

    static toFormData(keys, values) {
        const bodyFormData = new FormData()
        
        if (keys.length !== values.length) {
            throw new NoLengthMatchError('Keys and values must have the same length.')
        }
    
        keys.forEach((key, index) => {
            bodyFormData.append(key, values[index])
        })
        
        return bodyFormData
    }

    static locationsToFancyString(locations, separator = "\n\n") {
        const splitLocations = locations.map(location => {
            if (typeof location === "object") {
                location = JSON.stringify(location)
            }
            return location.split(",").map(item => item.trim())
        })

        const formattedLocations = splitLocations.map(locationGroup => locationGroup.join("\n"))
        const cleanedFormattedLocations = formattedLocations.map(
            formattedLocation => formattedLocation.slice(1, -1).replace(/"/g, "")
        )

        return cleanedFormattedLocations.join(separator)
    }
}