import React from 'react';
import styles from './GridListBoxCount.module.css';
import { GridListBox } from '../GridListBox/GridListBox';

export const GridListBoxCount = ({ itemCount, gridListBoxData, isCompanyListBoxEnabled }) => {
    return (
        <div className={styles["item-count-panel"]}>
            <div className={styles["item-count"]}>
                Number of locations: <strong>{itemCount}</strong>
            </div>
            <GridListBox
                data={gridListBoxData}
                isEnabled={!isCompanyListBoxEnabled}
            />
        </div>
    )
}
