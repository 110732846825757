
import styles from './Header.module.css';

export const Header = ({ rows, params, options }) => {
    return (
        <div className={styles[options.container]}>
            {rows.map((row, rowIndex) => (
                <div 
                    key={rowIndex} 
                    className={`${styles[options.row]}`}
                >
                    {row.map((item, colIndex) => {
                        const itemClassName = `${styles[options.item]} ${
                            item.newLine ? styles.newLine : ""
                        }`

                        return (
                            <div
                                key={colIndex}
                                className={itemClassName}
                                style={{
                                    paddingLeft: item.padding,
                                    flexBasis: item.newLine ? "100%" : "auto", 
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                 {item.component && item.component(...(params[rowIndex][colIndex] || []))}
                            </div>
                        )
                    })}
                </div>
            ))}
        </div>
    )
}


