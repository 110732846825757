import styles from './BatchAccessPage.module.css'
import { useEffect, useState } from "react"
import { UserAccessGrid } from "../../components/UserAccessGrid/UserAccessGrid"
import { setJWT, setExpirationTime } from "../../state/auth/authActions"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { refreshAccessToken } from "../../services/token"
import { selectAuthData } from "../../state/auth/authSelectors"
import { getAllUsers } from "../../services/users"
import { toast } from 'react-toastify';
import { BatchesAccess } from '../../models/batches'
import { Users } from '../../models/users'
import { selectSessionData } from '../../state/session/sessionSelectors'

export const BatchAccessPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const authData = useSelector(selectAuthData)

    const {
        userID,
    } = useSelector(selectSessionData)

    let [rows, setRows] = useState([])
    let [columns, setColumns] = useState([])

    const [ids, setIDs] = useState([userID])
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        const fetchAllUsers = async () => {
            const authContext = {
                isAuthenticated: true,
                jwt: authData.jwt,
                tokenExpiration: authData.expirationTime,
                dispatch: dispatch,
                setAccessToken: setJWT,
                setExpirationTime: setExpirationTime,
                refreshAccessTokenFn: refreshAccessToken,
                toLoadingPage: () => { navigate("/") }
            }
            
            const responseOrError = await getAllUsers(authContext)

            if(!responseOrError.success) {
                toast.error(`Error: Could not fetch all the users`)
                console.error(`Error: ${responseOrError.error}`)
            }

            const jsonUsers = responseOrError.data
            const users = Users.fromJsonToObject(jsonUsers)

            if (!Users.isUserListEmpty(users)) {
                [rows, columns] = BatchesAccess.toGridFormat(Users.excludeUserByID(users, userID))
                setRows(rows)
                setColumns(columns)    
            }

            setIsLoading(false)
        }

        fetchAllUsers()
    }, [])

    const onSelectedRows = (rowSelectionModel, _) => {
        setIDs(() => [userID, ...rowSelectionModel.filter(id => id !== userID)])
    }

    const idAccesses = (ids) => {
        return {
            ids: {
                accesses: ids.map(id => ({
                    user_id: id
                }))
            }
        }
    }

    return (
        <div className={styles["container"]}>
            <div className={styles["panel"]}>
                <h1 className={styles.title}>User Management</h1>
                <UserAccessGrid
                    columns={columns}
                    rows={rows}
                    onSelectedRows={onSelectedRows}
                    config={{ isLoading }}
                />
                <div className={styles["batch-access"]}>
                    <button
                        className={styles.button}
                        onClick={() => navigate("/home_page", { state: idAccesses(ids) })}
                    >
                        Give Access To Selected Users
                    </button>
                </div>
            </div>
        </div>
    )
}