// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_top-flex-container__vtufw {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.Header_top-flex-row__HpSe- {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.Header_top-flex-item__yGcSQ {
    flex: 1 1;
    max-width: 1000px;
}

.Header_top-down-grid-container__8TzJz {
    display: grid;                          
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
}

.Header_top-down-grid-row__McleG {
    display: contents; 
}

.Header_top-down-grid-item__jK4P5 {
    border-radius: 5px;                  
}

.Header_responsive-layout__p\\+z5V {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: nowrap;
} 

  @media (max-width: 1080px) {
    .Header_top-flex-container__vtufw {
        flex-direction: column;
        align-items: stretch;
    }

    .Header_top-flex-item__yGcSQ {
        max-width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 480px) {
    .Header_top-flex-item__yGcSQ {
        margin-bottom: 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,2DAA2D;IAC3D,WAAW;AACf;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,SAAS;IACT,iBAAiB;AACrB;;EAEE;IACE;QACI,sBAAsB;QACtB,oBAAoB;IACxB;;IAEA;QACI,eAAe;QACf,mBAAmB;IACvB;AACJ;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".top-flex-container {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    flex-wrap: wrap;\n}\n\n.top-flex-row {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    flex-wrap: wrap;\n}\n\n.top-flex-item {\n    flex: 1;\n    max-width: 1000px;\n}\n\n.top-down-grid-container {\n    display: grid;                          \n    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));\n    width: 100%;\n}\n\n.top-down-grid-row {\n    display: contents; \n}\n\n.top-down-grid-item {\n    border-radius: 5px;                  \n}\n\n.responsive-layout {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: space-between;\n    gap: 20px;\n    flex-wrap: nowrap;\n} \n\n  @media (max-width: 1080px) {\n    .top-flex-container {\n        flex-direction: column;\n        align-items: stretch;\n    }\n\n    .top-flex-item {\n        max-width: 100%;\n        margin-bottom: 10px;\n    }\n}\n\n@media (max-width: 480px) {\n    .top-flex-item {\n        margin-bottom: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-flex-container": `Header_top-flex-container__vtufw`,
	"top-flex-row": `Header_top-flex-row__HpSe-`,
	"top-flex-item": `Header_top-flex-item__yGcSQ`,
	"top-down-grid-container": `Header_top-down-grid-container__8TzJz`,
	"top-down-grid-row": `Header_top-down-grid-row__McleG`,
	"top-down-grid-item": `Header_top-down-grid-item__jK4P5`,
	"responsive-layout": `Header_responsive-layout__p+z5V`
};
export default ___CSS_LOADER_EXPORT___;
