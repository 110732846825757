import { combineReducers } from '@reduxjs/toolkit';
import { headerReducer }  from './header/headerReducer';
import { sessionReducer } from './session/sessionReducer';
import { authReducer } from './auth/authReducer'

export const rootReducer = combineReducers({
    session: sessionReducer,
    header: headerReducer,
    reducer: authReducer,
})
