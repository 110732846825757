export class CompanyHeader {
    static fromJsonToObject(companyHeaderJson) {
        return companyHeaderJson === undefined ?
        {} :
        CompanyHeader.toReadable(
            { 
                legal_company_name: companyHeaderJson.legal_company_name,
                company_name: companyHeaderJson.company_name,
                sector: companyHeaderJson.sector,
                function: companyHeaderJson.function,
                url: companyHeaderJson.url,
                locations_url: companyHeaderJson.locations_url,
            }
        )
    }   

    static toReadable(fields) { 
        const results = {}
        for (const [fieldKey, fieldValue] of Object.entries(fields)) {
            results[fieldKey] = [
                CompanyHeader.formatKeyForDisplay(fieldKey),
                fieldValue
            ]
        }

        return results
    }

    static formatKeyForDisplay(key) {
        return key
            .replace(/_/g, ' ')
            .replace(/\b\w/g, char => char.toUpperCase());
    }
}