import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { getProxyIFrame } from '../../services/iframe'
import { toast } from 'react-toastify'
import { selectAuthData } from '../../state/auth/authSelectors'
import { setJWT, setExpirationTime } from '../../state/auth/authActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { refreshAccessToken } from '../../services/token';

const IframeContainer = styled.div`
  position: relative;
  width: ${({ width }) => width};
  padding-top: ${({ height }) => height};
  overflow: hidden;
  max-width: 100%;
  max-height: 50vh;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: ${({ width }) => width === '100%' ? '85vw' : width};
  }

  @media (max-width: 992px) {
    width: ${({ width }) => width === '100%' ? '90vw' : width};
    height: ${({ height }) => height === '56.25%' ? '30vh' : height};
  }

  @media (max-width: 768px) {
    width: ${({ width }) => width === '100%' ? '95vw' : width};
    height: ${({ height }) => height === '56.25%' ? '35vh' : height};
  }

  @media (max-width: 480px) {
    width: ${({ width }) => width === '100%' ? '100vw' : width};
    height: ${({ height }) => height === '56.25%' ? '35vh' : height};
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export const IFrameBox = ({ src, title, width = "100%", height = "56.25%" }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const authData = useSelector(selectAuthData)
  const iframeRef = useRef(null)

  const parseSrc = (src) => {
    const [endpoint, companyURL] = src.split("?")
    return { endpoint, companyURL }
  }
  
  const cleanCompanyURL = (companyURL) => {
    return companyURL.replace("url=", "")
  }

  useEffect(() => {
    const fetchContent = async (authContext, endpoint, bodyParams) => {
      try {
        const content = await getProxyIFrame(authContext, endpoint, bodyParams)
        const blob = new Blob([content], { type: 'text/html' })
        const url = URL.createObjectURL(blob)
        iframeRef.current.src = url
      } catch (error) {
        console.error(`Error fetching content: ${error}`)
        toast.error("Error fetching content")
      }
    }

    const authContext = {
      isAuthenticated: true,
      jwt: authData.jwt,
      tokenExpiration: authData.expirationTime,
      dispatch: dispatch,
      setAccessToken: setJWT,
      setExpirationTime: setExpirationTime,
      refreshAccessTokenFn: refreshAccessToken,
      toLoadingPage: () => { navigate("/") }
    }
    const { endpoint, companyURL } = parseSrc(src)
    const cleanedURL = cleanCompanyURL(companyURL)
    const bodyParams = {
      "xYz": cleanedURL
    }
    
    fetchContent(authContext, endpoint, bodyParams)
  }, [src]) 


  return (
    <IframeContainer width={width} height={height}>
      <StyledIframe 
        ref={iframeRef}
        title={title}
      />
    </IframeContainer>
  )
}
