import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';

export const getProxyIFrame = async (authContext, endpoint, bodyParams) => {
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = accessToken 
    ? { 'Authorization': `Bearer ${accessToken}` } 
    : {}
    
    try {
        const response = await axios.post(
            endpoint,
            bodyParams,
            {
                headers: headers
            }
        )

        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}