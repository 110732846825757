// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0; 
    background-color: #f0f0f0; 
}

.BatchAccessPage_container__meICe {
    display: flex;
    flex-direction: column;
    background-color: #f0f4f8;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    padding: 20px; 
}

.BatchAccessPage_panel__3gCfh {
    background-color: #fff; 
    border-radius: 8px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    padding: 20px; 
    width: 100%; 
    max-width: 1250px; 
}

.BatchAccessPage_title__1aZD9 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: #333; 
}

.BatchAccessPage_batch-access__MTedL {
    display: flex;
    justify-content: center; 
    margin-top: 20px;
}

.BatchAccessPage_batch-access__MTedL button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border: none;
    background-color: #333;
    color: #fff;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 300;
}

.BatchAccessPage_batch-access__MTedL button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
    color: #666;
}

.BatchAccessPage_batch-access__MTedL button:hover {
    background-color: #555;
}

.BatchAccessPage_company-locations-grid__46ktm {
    max-width: 600px; 
    width: 100%;
    margin: 20px auto; 
}`, "",{"version":3,"sources":["webpack://./src/pages/BatchAccessPage/BatchAccessPage.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,iBAAiB;AACrB","sourcesContent":["body {\n    margin: 0; \n    background-color: #f0f0f0; \n}\n\n.container {\n    display: flex;\n    flex-direction: column;\n    background-color: #f0f4f8;\n    justify-content: center; \n    align-items: center; \n    height: 100vh; \n    padding: 20px; \n}\n\n.panel {\n    background-color: #fff; \n    border-radius: 8px; \n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); \n    padding: 20px; \n    width: 100%; \n    max-width: 1250px; \n}\n\n.title {\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: 2rem;\n    color: #333; \n}\n\n.batch-access {\n    display: flex;\n    justify-content: center; \n    margin-top: 20px;\n}\n\n.batch-access button {\n    width: 200px;\n    height: 60px;\n    margin: 10px;\n    border: none;\n    background-color: #333;\n    color: #fff;\n    border-radius: 8px;\n    font-size: 20px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n    font-weight: 300;\n}\n\n.batch-access button:disabled {\n    background-color: #aaa;\n    cursor: not-allowed;\n    color: #666;\n}\n\n.batch-access button:hover {\n    background-color: #555;\n}\n\n.company-locations-grid {\n    max-width: 600px; \n    width: 100%;\n    margin: 20px auto; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BatchAccessPage_container__meICe`,
	"panel": `BatchAccessPage_panel__3gCfh`,
	"title": `BatchAccessPage_title__1aZD9`,
	"batch-access": `BatchAccessPage_batch-access__MTedL`,
	"company-locations-grid": `BatchAccessPage_company-locations-grid__46ktm`
};
export default ___CSS_LOADER_EXPORT___;
