export const conditionalCommentChange = (reviewInputs) => {
    const updatedReviewInputs = { ...reviewInputs }
    
    if (updatedReviewInputs.urlReviewCategory === "Correct") {
        updatedReviewInputs.urlReviewComment = ""
    }
    if (updatedReviewInputs.locationsReviewCategory === "Correct") {
        updatedReviewInputs.locationsReviewComment = ""
    }

    return updatedReviewInputs
}