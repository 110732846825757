import { createSelector } from 'reselect';

const selectAuth = (state) => state.reducer

export const selectAuthData = createSelector(
    [selectAuth],
    (auth) => {
        return {
            fullName: auth.fullName,
            jwt: auth.jwt,
            expirationTime: auth.expirationTime,
            minutesValid: auth.minutesValid,
        }
    }
)

export const selectAccessToken = createSelector(
    [selectAuth],
    (auth) => {
        return {
            jwt: auth.jwt
        }
    }
)
