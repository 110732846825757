import React from 'react';
import { IgrMultiColumnComboBox } from 'igniteui-react-grids';
import { VerticalPadding } from '../VerticalPadding/VerticalPadding';
import { Batches } from '../../models/batches';

export const BatchSelectionComboBoxList = ({ batches, id, onSelectedValueChanged, isInteralUser = true }) => {
    const comboBoxConfig = {
        'allBatches': {
            width: '600px',
            height: '50px',
            defaultColumnWidth: 150,
            placeholder: 'All User Batches: Choose a batch from all the batches available.',
            dataSource: Batches.filteredBatchColumnsForInternalUserBatches(batches),
        },
        'internalUserBatches': {
            width: '600px',
            height: '50px',
            defaultColumnWidth: 200,
            placeholder: 'Internal Personal Batches: Choose a batch from your personal batches.',
            dataSource: Batches.filteredBatchColumnsForExternalUserBatches(
                Batches.filterBatchesByUserId(batches, id)
            ),
        },
        'externalUserBatches': {
            width: '600px',
            height: '50px',
            defaultColumnWidth: 200,
            placeholder: 'External Personal Batches: Choose a batch from your personal batches.',
            dataSource: Batches.filteredBatchColumnsForExternalUserBatches(batches),
        }
    }

    if(isInteralUser) {
        return (
            <div>
                <div>
                    <IgrMultiColumnComboBox
                        width={comboBoxConfig.allBatches.width}
                        height={comboBoxConfig.allBatches.height}
                        defaultColumnWidth={comboBoxConfig.allBatches.defaultColumnWidth}
                        placeholder={comboBoxConfig.allBatches.placeholder}
                        dataSource={comboBoxConfig.allBatches.dataSource}
                        selectedValueChanged={onSelectedValueChanged}
                    />
                </div>
                <VerticalPadding paddingSizeType="small" />
                <div>
                    <IgrMultiColumnComboBox
                        width={comboBoxConfig.internalUserBatches.width}
                        height={comboBoxConfig.internalUserBatches.height}
                        defaultColumnWidth={comboBoxConfig.internalUserBatches.defaultColumnWidth}
                        placeholder={comboBoxConfig.internalUserBatches.placeholder}
                        dataSource={comboBoxConfig.internalUserBatches.dataSource}
                        selectedValueChanged={onSelectedValueChanged}
                    />
                </div>
            </div>
        )
    }
     
    return (
        <div>
            <IgrMultiColumnComboBox
                    width={comboBoxConfig.externalUserBatches.width}
                    height={comboBoxConfig.externalUserBatches.height}
                    defaultColumnWidth={comboBoxConfig.externalUserBatches.defaultColumnWidth}
                    placeholder={comboBoxConfig.externalUserBatches.placeholder}
                    dataSource={comboBoxConfig.externalUserBatches.dataSource}
                    selectedValueChanged={onSelectedValueChanged}
            />
        </div>
    )
}


