import { date } from '../utils/helpers/format'
import { StringFormatter } from '../utils/helpers/stringFormatter'

export class Batches {
    static fromJsonToObject(jsonBatches) {
        return jsonBatches.map(jsonBatch => _Batch.fromJson(jsonBatch))
    }

    static filteredBatchColumnsForInternalUserBatches(batches) {
        return batches.map(batch => ({
            id: batch.id,
            batch_name: batch.batch_name,
            username: batch.username,
            uploaded_at: batch.uploaded_at
        }))
    }

    static filteredBatchColumnsForExternalUserBatches(batches) {
        return batches.map(batch => ({
            id: batch.id,
            batch_name: batch.batch_name,
            uploaded_at: batch.uploaded_at
        }))
    }

    static filterBatchesByUserId(batches, userID) {
        return batches.filter(batch => batch.user_id === userID)
    }

}

class _Batch {
    static fromJson(params) {
        return {
            id: params.id,
            batch_name: params.name,        
            username: params.username, 
            user_id: params.user_id,
            uploaded_at: date(params.uploaded_at) 
        }
    }
}

export class BatchesAccess {
    static toGridFormat(rows, width = 200) {
        const row = rows[0]
        let columns = Object.keys(row)

        columns = columns.map(column => ({ 
            field: column,
            headerName: StringFormatter.toGridFormat(column),
            width: width,
        }))

        return [rows, columns]
    }
}