let endpointsData = null

const loadEndpointsData = () => {
    if (endpointsData === null) {
        const request = new XMLHttpRequest()
        request.open("GET", "endpoints.json", false)
        request.send(null)

        if (request.status === 200) {
            endpointsData = JSON.parse(request.responseText)
        } else {
            console.error("Failed to load endpoints.json")
        }
    }
    return endpointsData
}

export const getEndpoints = loadEndpointsData

export const parseEndpoint = (endpoints, endpointParts) => {
    let currentPath = ""

    for (const part of endpointParts) {
        let found = false

        if (endpoints[part]) {
            currentPath += currentPath ? `/${part}` : part
            found = true
        } else {
            for (const [key, value] of Object.entries(endpoints)) {
                if (key === endpointParts[endpointParts.indexOf(part) - 1]) {
                    for (const item of value) {
                        if (item.name === part) {
                            currentPath += currentPath ? `/${item.name}` : item.name
                            found = true
                            break
                        }
                    }
                }
                if (found) break
            }
        }

        if (!found) {
            return null
        }
    }

    return currentPath
}