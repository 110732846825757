import { createSelector } from 'reselect';

const selectSession = (state) => state.session

export const selectSessionData = createSelector(
    [selectSession],
    (session) => ({
        username: session.username,
        batchID: session.batchID,
        userID: session.userID,
        fileName: session.fileName,
        fileLoader: session.fileLoader,
        fileContent: session.fileContent,
        canInsertBatch: session.insertBatch,
    })
)
