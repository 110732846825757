import React from 'react';
import styles from './NotFoundPage.module.css';

export const NotFoundPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.title}>404</h1>
        <h2 className={styles.subtitle}>Oops! Page Not Found</h2>
        <p className={styles.description}>
          The page you are looking for does not exist. It might have been moved or deleted.
        </p>
        <a href="/" className={styles.backLink}>Go Back to Home</a>
      </div>
    </div>
  )
}