export const parseApiError = (error) => {
    let errorMessage = null
    if (error.response) {
        const { status, data } = error.response
        switch (status) {
            case 400:
                errorMessage = `Bad Request: ${data.detail || 'Invalid request'}`
                break
            case 401:
                errorMessage = 'Unauthorized: Invalid or missing token'
                break
            case 403:
                errorMessage = 'Forbidden: You do not have permission to access this resource'
                break
            case 404:
                errorMessage = 'Not Found: The requested resource could not be found'
                break
            case 422:
                errorMessage = `The request data is invalid or incomplete. Please check the input and try again.`
                break
            case 429: 
                errorMessage = `${data.detail}. Please wait a moment before trying again`
                break
            case 500:
                errorMessage = ': Please try again later'
                break
            default:
                errorMessage = `Unexpected error: ${data.message || 'An unexpected error occurred'}`
        }
    } else if (error.request) {
        errorMessage = 'No response received from server'
    } else {
        errorMessage = `Error: ${error.message}`
    }

    return {
        success: false,
        error: errorMessage
    }
}