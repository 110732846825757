import React, { useState } from 'react'
import styles from './PopupPage.module.css'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { FancyInput } from '../../components/FancyPasswordInput/FancyInput'
import { IgrMultiColumnComboBoxModule } from 'igniteui-react-grids';
import { login } from '../../services/users'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { useDispatch } from 'react-redux';
import { authActions } from '../../state/auth/authActions'
import { DispatchManager } from '../../utils/helpers/dispatchManager'
import { DataConvertor } from '../../utils/helpers/convertors'
import { NoLengthMatchError } from '../../utils/errors'
import { Token } from '../../utils/authentication/auth'

export const PopupPage = () => {
    IgrMultiColumnComboBoxModule.register()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    
    const [openModal, setOpenModal] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const isLengthValid = (field, minLength = 3) => {
        return field.length >= minLength
    }

    const isFieldEmpty = (field) => {
        return field === ""
    }

    const attemptUserLogin = async (credentials, { setIsLoading }) => {
        setIsLoading(true)
    
        try {
            const bodyFormData = createFormData(credentials)
            const loginResponse = await login(bodyFormData)
    
            return handleLoginResponse(loginResponse)
        } catch (error) {
            return handleError(error)
        } finally {
            setIsLoading(false)
        }
    }
    
    const createFormData = ({ username, password }) => {
        return DataConvertor.toFormData(
            ["username", "password"],
            [username, password]
        )
    }
    
    const handleLoginResponse = (response) => {
        if (!response.success) {
            return {
                consoleError: response.detail || response.error,
                toastError: response.detail || response.error,
            }
        }

        return {
            auth: {
                fullName: response.user.full_name,
                jwt: response.access_token,
                minutesValid: response.minutes_valid,
            },
            user: {
                isInternalUser: response.user.internal_user,
                id: response.user.id
            }
        }
    }
    
    const handleError = (error) => {
        if (error instanceof NoLengthMatchError) {
            console.error(`Length mismatch error: ${error.message}`)
            return {
                consoleError: error.message,
                toastError: error.message,
            }
        } else {
            console.error(`An unexpected error occurred: ${error}`)
            return {
                consoleError: "An unexpected error occurred.",
                toastError: "An unexpected error occurred.",
            }
        }
    }

    const handleUserLoginFlow = async (
        path, dispatchObject, dispatchFn, actions, expirationTimeFn, navigate, modalSetter
    ) => {
        if(isFieldEmpty(username) || isFieldEmpty(password)) {
            toast.error("Please enter both your username and password.")
            return             
        }

        const credentials = {
            username: username.toLowerCase(),
            password: password
        }
        const userLoginData = await attemptUserLogin(credentials, { setIsLoading })

        if(userLoginData?.toastError) {
            console.error(`Error: ${userLoginData.consoleError}`)
            toast.error(userLoginData.toastError)
            return
        }

        const accessTokenExpirationTime = expirationTimeFn(userLoginData.auth.minutesValid)
        userLoginData["auth"]["expirationTime"] = accessTokenExpirationTime

        const userLoginAttributes = Object.values(userLoginData.auth)
        dispatchFn(dispatchObject, actions, userLoginAttributes)

        modalSetter(false)
        navigate(
            path,
            { state: { 
                id: userLoginData.user.id,
                username: username,
                isInternalUser: userLoginData?.user?.isInternalUser
            } 
        })
    }

    const onButtonLogin = async () => await handleUserLoginFlow(
        "data_selection", 
        dispatch, 
        DispatchManager.all, 
        authActions, 
        Token.calculateExpirationTime,
        navigate, 
        setOpenModal
    )

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value)
    }

    if (!openModal) {
        return (
            <div></div>
        )
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles["modalBackground"]}>
            <div className={styles["modalContainer"]}>
                <div className={styles["title"]}>
                    <h1>Ready to Load Batch Data</h1>
                </div>
                <div>
                    <div className={styles["instruction"]}>  
                        <h4>Please enter the username to continue.</h4>
                    </div>

                    <FancyInput
                        value={username}
                        onChange={handleInputChange(setUsername)}
                        placeholder="Username"
                    />
                    
                    <div className={styles["instruction"]}>  
                        <h4>Please enter the password to continue.</h4>
                    </div>
                    <FancyInput
                        type="password"
                        value={password}
                        onChange={handleInputChange(setPassword)}
                        placeholder="Password"
                    />   
                </div>
                <div className={styles["login"]}>
                    <button
                        onClick={onButtonLogin}
                        disabled={!isLengthValid(password) || !isLengthValid(username)}
                    >Login</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}


