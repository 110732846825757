import { FileLoad } from '../../../utils/enums/files'
import { fetchAllCompanies } from '../../../services/companies'
import { toast } from 'react-toastify'
import { Companies } from '../../../models/companies'
import { createBatch } from '../../../services/batches'
import { Locations } from '../../../models/locations'
import { fetchCompanyLocations } from '../../../services/locations'
import { fetchAnalyticsCSV } from '../../../services/analytics'
import { handleCSVDownload } from '../../../utils/helpers/handleCSVDownload'
import { CompanyHeader } from '../../../models/companyHeader'
import { triggerFancyPopup } from '../../../components/FancyPopup/FancyPopup'
import { Feedback } from '../../../models/feedback'
import { BASE_URL, NO_ENDPOINT_ERROR } from '../../constants'
import { getEndpoints, parseEndpoint } from '../endpoints'

const fetchAndSetCompanies = async (fetchParams) => {
    const { setterIsLoading, setterCompanies, batchID, authContext } = fetchParams 

    const companiesResponse = await fetchAllCompanies(authContext, batchID)
    if(!companiesResponse.success) {
        console.error(`Error in ${loadFromFilePicker.name}:`, companiesResponse)
        toast.error("Failed to fetch companies")
        setterIsLoading(false)
        return
    }

    const companiesSerialized = Companies.fromJsonToObject(companiesResponse.companies)
    setterCompanies(companiesSerialized)
    setterIsLoading(false)
}

const loadFromFilePicker = async (filePickerParams) => {
    const { 
        setterIsLoading, 
        setterCompanies, 
        createBatchParams, 
        setNewBatchID, 
        getNewBatchID, 
        insertBatchRef,
        disableBatchInsert,
        dispatch,
        setBatchID,
        setInsertBatch,
        canInsertBatch,
        authContext
    } = filePickerParams
    
    if(insertBatchRef.current) {
        const batchResponse = await createBatch(authContext, createBatchParams)
        if(!batchResponse.success) {
            console.error(`Error in method ${loadFromFilePicker.name} : ${createBatchParams.errorMessage}`)
            triggerFancyPopup(`Failed to create batch. Error: ${batchResponse.error}`)
            setterIsLoading(false)
            return
        }

        // Update the batch ID with the value received from the API response
        disableBatchInsert()
        setNewBatchID(batchResponse.batchID)

        dispatch(setBatchID(batchResponse.batchID))
        dispatch(setInsertBatch(canInsertBatch))
    }

    const params = {
        setterIsLoading: setterIsLoading,
        setterCompanies: setterCompanies,
        batchID: getNewBatchID(),
        authContext: authContext,
    }
    await fetchAndSetCompanies(params)
}

const loadFromComboBox = async (comboBoxParams) => {
    const { setterIsLoading, setterCompanies, batchID, authContext } = comboBoxParams

    const params = {
        setterIsLoading: setterIsLoading,
        setterCompanies: setterCompanies,
        batchID: batchID,
        authContext: authContext
    }
    await fetchAndSetCompanies(params)
}

export const handleFileLoad = async (fileLoader, params) => {
    const fileLoaders = {
        [FileLoad.FILE_PICKER]: loadFromFilePicker,
        [FileLoad.COMBO_BOX]: loadFromComboBox,
    }

    const loaderFunction = fileLoaders[fileLoader]

    if (loaderFunction) {
        await loaderFunction(params)
    } else {
        console.error(`No such file loader, actual file loader is: ${fileLoader}`)
        toast.error("Error: No such file loader.")
    }
}

export const onSelectedCompanyHelper = async (company, authContext, setters, options, resetFeedbackFn, setVisibilityFn, feedbackFieldsDefaultValue) => {
    try {
        if (!company) {
            toast.error("Company does not exist.")
            return
        }

        const companyLocations = await fetchCompanyLocations(authContext, company.companyID)
        
        const locations = Locations.fromJsonToObject(companyLocations.locations)
        
        const companyDetails = CompanyHeader.fromJsonToObject(companyLocations.company)

        const feedbacks = companyLocations?.company?.feedbacks

        if(Feedback.existsFeedbacks(feedbacks)) {
            const companyFeedback = Feedback.fromJsonToObject(Feedback.getLastElement(feedbacks), options)

            setters.updateFeedbackFieldsVisibility(
                setters.feedbackFieldsVisibility,
                [companyFeedback.urlReviewCategory, companyFeedback.locationsReviewCategory]
            )
            
            Feedback.setFeedbackData(setters.feedback, companyFeedback)
        } else {
            resetFeedbackFn(feedbackFieldsDefaultValue, ...setters.feedback)
            setVisibilityFn(setters.feedbackFieldsVisibility)
        }

        const mapCoordinates = Locations.getLocationsCoordinates(locations)
        setters.setMapCoordinates(mapCoordinates)

        const companyLocationsInformation = Locations.getLocationsInformation(locations)
        setters.setCompanyLocationsInformation(companyLocationsInformation)

        const parsedEndpoint = parseEndpoint(getEndpoints(), ["iframe_proxy"])
    
        if(parsedEndpoint === null) {
            console.error(`Error: ${NO_ENDPOINT_ERROR}`)
            toast.error(`${NO_ENDPOINT_ERROR}`)
            return
        }

        setters.setIFrameUrl(
            `${BASE_URL}/` +
            `${parsedEndpoint}?url=${locations.at(-1).source_link}`
        )
        
        setters.setCompanyID(company.companyID)
        setters.setCompanyInputValue(company.companyName)
        setters.setSelectedCompany({
            ...company,
            details: {
                ...companyDetails
            }
        })
        setters.handleCompanyListBox(setters.setIsCompanyListBoxEnabled)
   
    } catch (error) {
        console.error(`Error: ${error}`)
        toast.error("An error occurred while fetching the locations of a company.")
    }
}

export const onButtonDownloadAnalyticsCSVHelper = async (_, authContext, params) => {
    if (params.userID === null || typeof params.userID !== "number") {
        toast.error("Could not download CSV successfully because the user was not provided")
        return
    }

    if(params.batchID === null || typeof params.batchID !== "number") {
        toast.error("Could not download CSV successfully because the batch was not provided")
        return
    }

    const requestData = {
        headers: {
            contentType: "text/csv"
        }
    }

    try {
        const blob_or_error = await fetchAnalyticsCSV(
            authContext,
            requestData, 
            { 
                user_id: params.userID, 
                batch_id: params.batchID 
            },
        )

        if(!blob_or_error.success) {
            console.error(`Blob error: ${blob_or_error.error}`)
            toast.error("An error occurred while processing the request. The server encountered an issue. Please try again later or contact support if the issue persists.")
            return
        }

        const fileNameForDownload = "analysis.csv"
        const isCSVDownloaded = handleCSVDownload(blob_or_error.data, fileNameForDownload)

        if (typeof isCSVDownloaded === "undefined") {
            toast.success("Downloaded CSV successfully, please check your Downloads folder")
        } else {
            toast.error("Failed to download CSV.")
        }
    } catch (error) {
        toast.error("An unexpected error occurred while submitting data.")
    }
}

export const updateCompanyFeedbackStatus = (companies, companyID) => {
    const companyIndex = Companies.findCompanyIndexByID(companyID, companies)
    if (companyIndex !== -1) {
        const updatedCompany = {
            ...companies[companyIndex],
            has_feedbacks: true,
        }

        return [
            ...companies.slice(0, companyIndex),
            updatedCompany,
            ...companies.slice(companyIndex + 1),
        ]
    }

    return companies
}

export const updateFeedbackFieldsVisibility = (setters, feedbackfields, visibilityOption = "option1") => {
    feedbackfields.forEach((feedbackField, i) => {
        const isVisible = feedbackField === visibilityOption
        setters[i](!isVisible)
    })
}

export const isSubmitButtonEnabled = (options, selectedOption, optionExistsFn) => {
    return optionExistsFn(options.url, selectedOption.url) && optionExistsFn(options.locations, selectedOption.locations)
}