import {
    FULL_NAME,
    JWT,
    EXPIRATION_TIME,
    MINUTES_VALID
} from './authActionTypes'

const initialState = {
    fullName: "",
    jwt: "",
    expirationTime: 0,
    minutesValid: 0
}

const actionTypeToStateKeyMap = {
    [FULL_NAME]: "fullName",
    [JWT]: "jwt",
    [EXPIRATION_TIME]: "expirationTime",
    [MINUTES_VALID]: "minutesValid"
}

export const authReducer = (state = initialState, action) => {
    const { type, payload } = action

    const stateKey = actionTypeToStateKeyMap[type]
    
    if (stateKey) {
        return {
            ...state,
            [stateKey]: payload.value
        }
    }

    return state
}