import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { getEndpoints, parseEndpoint } from '../utils/helpers/endpoints';
import { BASE_URL, NO_ENDPOINT_ERROR } from '../utils/constants';
import { Token } from '../utils/authentication/auth';

export const login = async (bodyFormData) => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["users", "login"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    try {
        const response = await axios.post(
            `${BASE_URL}/${parsedEndpoint}`,
            
            bodyFormData,
            { 
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true 
            }
        )

        return {
            success: true,
            ...response.data
        }
        
    } catch (error) {
        return parseApiError(error)
    }
}

export const logout = async () => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["users", "login"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }

    try {
        const response = await axios.delete(
            `${BASE_URL}/${parsedEndpoint}/refresh_token`,
            { 
                withCredentials: true 
            }
        )

        return {
            success: true,
            ...response.data
        }

    } catch (error) {
        return parseApiError(error)
    }
}

export const getAllUsers = async (authContext) => {
    const parsedEndpoint = parseEndpoint(getEndpoints(), ["users"])
    
    if(parsedEndpoint === null) {
        return {
            success: false,
            error: NO_ENDPOINT_ERROR
        }
    }
    
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = accessToken 
    ? { 'Authorization': `Bearer ${accessToken}` } 
    : {}

    try {
        const response = await axios.get(
            `${BASE_URL}/${parsedEndpoint}`,
            {
                headers: headers
            }
        )
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return parseApiError(error)
    }
}