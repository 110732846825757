import React from 'react';
import styles from './FancyInput.module.css';

export const FancyInput = ({
    type = "text",
    value,
    onChange,
    placeholder,
    className = "",
    ...props
}) => {
    return (
        <input
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={`${styles.inputField} ${className}`} 
            {...props} 
        />
    )
}

