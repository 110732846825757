import {
    FULL_NAME,
    JWT,
    EXPIRATION_TIME,
    MINUTES_VALID
} from './authActionTypes';

const setFullName = (value) => ({
    type: FULL_NAME,
    payload: { value },
})

export const setJWT = (value) => ({
    type: JWT,
    payload: { value },
})

export const setExpirationTime = (value) => ({
    type: EXPIRATION_TIME,
    payload: { value },
})

const setMinutesValid = (value) => ({
    type: MINUTES_VALID,
    payload: { value },  
})

export const authActions = [
    setFullName,
    setJWT,
    setMinutesValid,
    setExpirationTime
]
